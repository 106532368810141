import React, { useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { Button, MonthPicker } from '../../common/customComponents'
import { t } from '../../../infrastructure/i18nextHelper'
import { formatFilename } from '../../../infrastructure/excelExport'
import * as api from '../../../infrastructure/api'
import { reportStyles, ReportShell } from './_common'

function _compulsoryStock({ classes }: MuiProps) {
    let currentMonth = moment().format('MM/DD/YYYY')
    let [compulsoryStockFromDate, setCompulsoryStockFromDate] = useState<string | null>(currentMonth)

    let generateCompulsoryStock = async () => {
        if (!compulsoryStockFromDate) return

        let filename = formatFilename(`${moment(compulsoryStockFromDate).format('MM-YYYY')}_CompulsoryStock.xlsx`)
        await api.post('report/compulsoryStock', { month: compulsoryStockFromDate, filename: filename })

        let url = `${api.url}download/${filename}`

        window.open(url, '_blank')
    }

    return (
        <ReportShell title={t('report.compulsoryStock')} displayError={false}>
            <div className={classes.form}>
                <Button className={classes.generateButton}
                    label={t('report.export')}
                    img='/static/images/excel_red.svg'
                    onClick={generateCompulsoryStock} />
            </div>
            <MonthPicker classesOverride={{ datepicker: classes.datePicker }} date={compulsoryStockFromDate}
                label={t('report.month')} setDate={setCompulsoryStockFromDate} />
        </ReportShell>
    )
}

let styles = theme => createStyles({
    generateButton: defaultStyles.secondaryButton,
    datePicker: reportStyles.datePicker,
    form: reportStyles.form,
})

export default withStyles(styles, muiOptions)(_compulsoryStock)