
import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { UserContextContainer } from '../../../infrastructure/signIn/userContext'
import { api } from '../../../infrastructure/api'
import { ColumnDescriptor, TableItem, TextField, NumberField, Select, MultipleSelect } from '../../common/customComponents'
import { MasterDataItem, MasterDataShell } from './masterDataShell'
import { hasFeature } from '../../../infrastructure/feature'
import { applyFilters } from '../../common/filtersHelper'
import { PriceIndex } from '../../pricings/pricingModels'

type BaseProduct = {
    id: string,
    code: string
}

function LocalProductMasterData({ classes }: MuiProps) {
    let [localProduct, setLocalProduct] = useState<TableItem<LocalProduct>[]>([])
    let [baseProducts, setBaseProducts] = useState<BaseProduct[]>([])
    let [priceIndexes, setPriceIndexes] = useState<PriceIndex[]>([])
    let [filters, setFilters] = useState<LocalProductFilters>(noFilters)

    let user = UserContextContainer.useContainer()

    const tBase = 'admin.masterdata.localProduct.'

    let load = async () => {
        let existingPriceIndexes = api.get<PriceIndex[]>('masterdata/localProduct/priceIndexes')
        let localProduct = api.get<TableItem<LocalProduct>[]>('masterdata/localProduct')
        let existingProducts = api.get<BaseProduct[]>('masterdata/localProduct/products')

        setPriceIndexes(await existingPriceIndexes)
        setLocalProduct(await localProduct)
        setBaseProducts(await existingProducts)
    }

    let getItems = () => applyFilters(localProduct, filters).map(toTableItem)

    useEffect(() => {
        load()
    }, [])

    let onSave = async (item: LocalProduct & IsNew) => {
        await api.post('masterdata/localProduct', item)
        await load()
        return true
    }

    let getBooleanLabel = (value: boolean): string => value
        ? t(tBase + 'isYes')
        : t(tBase + 'isNo')

    let columns: ColumnDescriptor<TableItem<MasterDataItem<LocalProduct>>>[] = [
        {
            name: t(tBase + 'product'),
            value: x => x.product,
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product }) }
        },
        {
            name: t(tBase + 'name'),
            value: x => x.name,
            columnFilter: { value: filters.name ?? '', onChange: (name: string) => setFilters({ ...filters, name }) }
        },
        {
            name: t(tBase + 'unit'),
            value: x => x.unit,
            columnFilter: { value: filters.unit ?? '', onChange: (unit: string) => setFilters({ ...filters, unit }) }
        },
        {
            name: t(tBase + 'transportUnit'),
            value: x => x.transportUnit,
            columnFilter: { value: filters.transportUnit ?? '', onChange: (transportUnit: string) => setFilters({ ...filters, transportUnit }) }
        },
        {
            name: t(tBase + 'standardDensity'),
            value: x => x.standardDensity,
        },
        {
            name: t(tBase + 'contractualDensity'),
            value: x => x.contractualDensity,
        },
        {
            name: t(tBase + 'bblToConversionFactor'),
            value: x => x.bblToConversionFactor ?? '',
        },
        {
            name: t(tBase + 'priceIndexs'),
            value: x => x.priceIndexs.filter(item => item !== "").join(', '),
            columnFilter: { value: filters.priceIndexs ?? '', onChange: (priceIndexs: string) => setFilters({ ...filters, priceIndexs }) }
        },
        {
            name: t(tBase + 'defaultPriceIndex'),
            value: x => x.defaultPriceIndex ?? '',
            columnFilter: { value: filters.defaultPriceIndex ?? '', onChange: (defaultPriceIndex: string) => setFilters({ ...filters, defaultPriceIndex }) }
        },
        {
            name: t(tBase + 'defaultProvisionalPriceIndex'),
            value: x => x.defaultProvisionalPriceIndex ?? ''
        },
        {
            name: t(tBase + 'vesselVisible'),
            value: x => getBooleanLabel(x.vesselVisible) ?? ''
        },
        {
            name: t(tBase + 'order'),
            value: x => x.order
        }
    ]

    let onDelete = async (ids: string[] | null) => {
        if (ids == null) return false

        let deletePromises = localProduct
            .filter(x => ids.includes(x.productId))
            .map(x => api.post('masterdata/localProduct/delete', { country: user.currentCountry, ProductId: x.productId }))
        await Promise.all(deletePromises)

        await load()
        return true
    }

    let getDefaultPriceIndex = (selectedPriceIndexs: string[], defaultPriceIndex: string) =>
        selectedPriceIndexs.filter(item => item !== "").includes(defaultPriceIndex)
            ? defaultPriceIndex : selectedPriceIndexs.first()

    let getDefaultProvisionalPriceIndex = (selectedPriceIndexes: string[], defaultProvisionalPriceIndex: string) => {
        let selected = selectedPriceIndexes.filter(x => x !== "")
        if (hasFeature('RegulatedPriceAsDefaultProvisionalPrice'))
            selected = selected.filter(x => priceIndexes.find(pi => pi.medecoCode === x)?.type === 'RegulatedPrice')
        return selected.includes(defaultProvisionalPriceIndex)
            ? defaultProvisionalPriceIndex : selected[0] ?? ""
    }

    let defaultPriceIndexChoices = (selectedPriceIndexes: string[]) => {
        return priceIndexes.filter(x => selectedPriceIndexes.some(c => c === x.medecoCode))
    }

    let defaultProvisionalPriceIndexChoices = (selectedPriceIndexes: string[]) => {
        let choices = priceIndexes.filter(x => selectedPriceIndexes.some(c => c === x.medecoCode))
        if (hasFeature('RegulatedPriceAsDefaultProvisionalPrice'))
            return choices.filter(x => x.type === 'RegulatedPrice')
        return choices
    }

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'localProduct-table'}
                headerLabel={t(tBase + 'localProduct')}
                itemLabel={t(tBase + 'localProduct')}
                isManager={true}
                onNew={() => emptyLocalProduct(user.currentCountry)}
                onSave={onSave}
                items={getItems()}
                onDelete={onDelete}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t(tBase + 'product')}
                                disabled={!selectedItem.isNew}
                                value={selectedItem.productId}
                                choices={baseProducts.map(x => ({ value: x.id, text: x.code }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, product: baseProducts.find(x => x.id == val)!.code, productId: val }) }} />
                            <TextField label={t(tBase + 'name')}
                                text={selectedItem.name}
                                onChange={event => setSelectedItem({ ...selectedItem, name: event.target.value })} />
                            <TextField label={t(tBase + 'unit')}
                                disabled={!selectedItem.isNew}
                                text={selectedItem.unit}
                                onChange={event => setSelectedItem({ ...selectedItem, unit: event.target.value })} />
                            <TextField label={t(tBase + 'transportUnit')}
                                disabled={!selectedItem.isNew}
                                text={selectedItem.transportUnit}
                                onChange={event => setSelectedItem({ ...selectedItem, transportUnit: event.target.value })} />
                            <NumberField label={t(tBase + 'standardDensity')}
                                text={selectedItem.standardDensity}
                                precision={9}
                                maxLength={12}
                                onChange={value => setSelectedItem({ ...selectedItem, standardDensity: value })} />
                            <NumberField label={t(tBase + 'contractualDensity')}
                                text={selectedItem.contractualDensity}
                                precision={9}
                                maxLength={12}
                                onChange={value => setSelectedItem({ ...selectedItem, contractualDensity: value })} />
                            <NumberField label={t(tBase + 'bblToConversionFactor')}
                                text={selectedItem.bblToConversionFactor}
                                precision={9}
                                maxLength={12}
                                onChange={value => setSelectedItem({ ...selectedItem, bblToConversionFactor: value })} />
                            <Select label={t(tBase + 'defaultPriceIndex')}
                                value={selectedItem.defaultPriceIndex}
                                isClearable={false}
                                choices={defaultPriceIndexChoices(selectedItem.priceIndexs).map(x => ({ value: x.medecoCode, text: x.medecoCode + ' - ' + x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, defaultPriceIndex: val }) }} />
                            <Select label={t(tBase + 'defaultProvisionalPriceIndex')}
                                value={selectedItem.defaultProvisionalPriceIndex}
                                isClearable={false}
                                choices={defaultProvisionalPriceIndexChoices(selectedItem.priceIndexs).map(x => ({ value: x.medecoCode, text: x.medecoCode + ' - ' + x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, defaultProvisionalPriceIndex: val }) }} />
                            <MultipleSelect label={t(tBase + 'priceIndexs')}
                                classesOverride={{ form: classes.filterField }}
                                values={selectedItem.priceIndexs}
                                choices={priceIndexes.map(x => ({ value: x.medecoCode, text: x.medecoCode + ' - ' + x.name }))}
                                disableNewStyle
                                allWhenEmpty={false}
                                onChange={val => setSelectedItem({
                                    ...selectedItem, priceIndexs: val,
                                    defaultPriceIndex: getDefaultPriceIndex(val, selectedItem.defaultPriceIndex),
                                    defaultProvisionalPriceIndex: getDefaultProvisionalPriceIndex(val, selectedItem.defaultProvisionalPriceIndex)
                                })} />
                            <div className={classes.isBooleanContainer}>
                                <Typography variant='subtitle1'>{t(tBase + 'vesselVisible')}</Typography>
                                <Checkbox checked={selectedItem.vesselVisible} onChange={x => setSelectedItem({ ...selectedItem, vesselVisible: x.target.checked })} />
                            </div>
                            <NumberField label={t(tBase + 'order')}
                                text={selectedItem.order}
                                precision={2}
                                onChange={value => setSelectedItem({ ...selectedItem, order: value })} />
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

export type LocalProduct = {
    country: string
    productId: string
    product: string
    name: string
    unit: string
    transportUnit: string
    standardDensity: number
    contractualDensity: number | null
    bblToConversionFactor: number
    defaultPriceIndex: string
    defaultProvisionalPriceIndex: string
    priceIndexs: string[]
    vesselVisible: boolean
    order: number
}

type IsNew = { isNew: boolean }

let emptyLocalProduct = (country: string): LocalProduct & IsNew => ({
    country: country,
    productId: '',
    product: '',
    name: '',
    unit: '',
    transportUnit: '',
    standardDensity: 1,
    contractualDensity: null,
    bblToConversionFactor: 1,
    defaultPriceIndex: '',
    defaultProvisionalPriceIndex: '',
    priceIndexs: [],
    vesselVisible: true,
    order: 1,
    isNew: true
})

let toTableItem = (localProduct: LocalProduct): TableItem<MasterDataItem<LocalProduct>> => {
    return {
        ...localProduct,
        id: localProduct.productId,
        priceIndexs: localProduct.priceIndexs,
        isModified: false
    }
};

type LocalProductFilters = {
    product: string | null,
    name: string | null,
    unit: string | null,
    transportUnit: string | null,
    priceIndexs: string | null,
    defaultPriceIndex: string | null
}

let noFilters: LocalProductFilters = {
    product: null,
    name: null,
    unit: null,
    transportUnit: null,
    priceIndexs: null,
    defaultPriceIndex: null
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        isBooleanContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-around',
            "& >span": { padding: '0' }
        }
    })
export default withStyles(styles, muiOptions)(LocalProductMasterData)