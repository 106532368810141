import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { MuiProps, muiOptions, defaultColors } from '../materialUiThemeProvider'
import { Drawer, withStyles, createStyles } from '@material-ui/core'
import * as Icons from '@material-ui/icons'
import { FeatureContainer, hasFeature } from './../../infrastructure/feature'
import { api } from './../../infrastructure/api'
import { hasReportAccess } from '../../app/report/report'
import { navigateTo } from '../navigation'
import { Claims } from '../signIn/models'
import { hasAdminClaim, hasClaim, UserContextContainer, hasVesselClaim, hasMarketRiskClaim } from '../signIn/userContext'
import { t } from './../../infrastructure/i18nextHelper'
import { UserSettingsContextContainer } from './userSettings/userSettingsStore'
import { getEnvironment } from '../environment'

function Menu({ classes, theme, openDrawer, closeDrawer, isOpen }: MuiProps & { openDrawer: () => void, closeDrawer: () => void, isOpen: boolean }) {
    return (
        <>
            <div>
                <Drawer
                    variant='permanent'
                    anchor={theme ? (theme.direction === 'rtl' ? 'right' : 'left') : 'left'}
                    open={isOpen}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: isOpen,
                        [classes.drawerClose]: !isOpen,
                    })}
                    classes={{
                        paper: clsx(classes.drawerPaper, {
                            [classes.drawerOpen]: isOpen,
                            [classes.drawerClose]: !isOpen
                        })
                    }}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    onMouseEnter={openDrawer}
                    onMouseLeave={closeDrawer}>
                    <div className={classes.drawerContent}>
                        <MenuList classes={classes} />
                        <MenuFooter classes={classes} />
                    </div>
                </Drawer>
            </div>
        </>
    )
}

type MenuItemDefinition = {
    path?: string
    navigate?: (() => void)
    icon: any
    text: string
    show: boolean
}

function MenuList({ classes }: MuiProps) {
    let feature = FeatureContainer.useContainer()
    let user = UserContextContainer.useContainer()
    let userSettings = UserSettingsContextContainer.useContainer()

    let getMenuItems = () => [
        {
            path: '/stock',
            icon: <Icons.EqualizerOutlined />,
            text: t('stock.name'),
            show: hasClaim(Claims.StockManager)
        },
        {
            path: '/supplyBalance',
            icon: <img className={'MuiSvgIcon-root'} src='../../static/images/balance_icon.svg' />,
            text: t('supplyBalance.name'),
            show: hasClaim(Claims.SupplyBalanceManager) || hasClaim(Claims.SupplyBalanceReader)
        },
        {
            path: '/deals',
            icon: <Icons.GavelOutlined />,
            text: t('deals.name'),
            show: hasFeature('Deals') && (hasClaim(Claims.DealManager) || hasClaim(Claims.DealReader))
        },
        {
            path: '/vessels',
            icon: <Icons.DirectionsBoatOutlined />,
            text: t('vessels.name'),
            show: hasVesselClaim()
        },
        {
            path: '/mktSales',
            icon: <Icons.LocalAtm />,
            text: t('mktSales.name'),
            show: hasClaim(Claims.SalesManager) || hasClaim(Claims.SalesReader)
        },
        {
            path: '/mktRiskExposure',
            icon: <img className={'MuiSvgIcon-root'} src='../../static/images/marketRiskIcon.svg' />,
            text: t('mktRiskExposure.name'),
            show: hasMarketRiskClaim()
        },
        {
            path: '/trucks',
            icon: <Icons.LocalShippingOutlined />,
            text: t('trucks.name', { __mot__: 'Truck' }),
            show: (hasClaim(Claims.TruckManager) || hasClaim(Claims.TruckReader)) && feature.hasFeature('Trucks')
        },
        {
            path: '/railCars',
            icon: <Icons.Train />,
            text: t('trucks.name', { __mot__: 'Rail Car' }),
            show: (hasClaim(Claims.RailCarsManager) || hasClaim(Claims.RailCarsReader)) && feature.hasFeature('RailCars')
        },
        {
            path: '/report',
            icon: <Icons.DescriptionOutlined />,
            text: t('report.name'),
            show: hasReportAccess()
        },
        {
            path: '/admin',
            icon: <Icons.BuildOutlined />,
            text: t('admin.name'),
            show: hasAdminClaim()
        },
    ]

    let [items, setItems] = useState<MenuItemDefinition[]>(getMenuItems())

    useEffect(() => {
        let reload = async () => {
            await feature.reload()
            setItems(getMenuItems())
        }
        reload()
    }, [user.currentCountry, user.profile, userSettings.userSettings.language])

    return (
        <div className={classes.menuContainer}>
            {items.map(item => <MenuItem key={item.text} item={item} classes={classes} />)}
        </div>
    )
}

function MenuFooter({ classes }: { classes: any }) {
    let userStore = UserContextContainer.useContainer()
    let currentCountryRef = React.useRef<string>(userStore.currentCountry)
    React.useEffect(() => { currentCountryRef.current = userStore.currentCountry }, [userStore.currentCountry])

    let openPowerBi = () => {
        window.open(powerBiUrl, '_blank')
    }

    function openContact() {
        let mail = 'ms.digitalaccelerationteam@totalenergies.com'
        let subjet = 'LYNKS - Incident report'
        let body = 'Please fill in the following information, we will come back to you as soon as possible.\n' +
            '\n' +
            'Your IGG: \n' +
            'Country: ' + t(`country.${currentCountryRef.current}`) + '\n' +
            'Severity (Low / Medium / High): \n' +
            'Describe your problem:\n\n' +
            'Steps that lead to the problem:\n\n' +
            'Fullscreen capture:\n\n\n'
        let url = `mailto:${mail}?subject=${subjet}&body=${encodeURI(body)}`

        window.open(url, '_blank')
    }

    async function downloadIntegrityCheckReport() {
        let report = await api.get<{ errors: string }>('integrityChecks')
        let fileName = 'integrity-checks.txt'
        const link = document.createElement('a')
        const url = URL.createObjectURL(new File([report.errors], fileName, {
            type: 'text/plain'
        }))

        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
    }

    let powerBiUrl = getEnvironment() === 'Staging' || getEnvironment() === 'Qualification' ?
        'https://app.powerbi.com/Redirect?action=OpenApp&appId=728fce2f-402e-4c48-a472-37ad6187939d&ctid=329e91b0-e21f-48fb-a071-456717ecc28e'
        :
        'https://app.powerbi.com/Redirect?action=OpenApp&appId=eff78ba9-65e4-40c8-8c3a-d3dc0f56440e&ctid=329e91b0-e21f-48fb-a071-456717ecc28e'

    return (
        <div>
            <MenuItem item={{
                navigate: downloadIntegrityCheckReport,
                icon: <Icons.BugReportOutlined />,
                text: t('shell.integrityCheckReport'),
                show: hasClaim(Claims.IntegrityCheckReport)
            }} classes={classes} />
            <MenuItem item={{
                navigate: openPowerBi,
                icon: <img className={'MuiSvgIcon-root'} src='../../static/images/powerbi.png' />,
                text: t('shell.powerbi'),
                show: !!powerBiUrl
            }} classes={classes} />
            <MenuItem item={{
                navigate: openContact,
                icon: <Icons.LiveHelpOutlined />,
                text: t('shell.contact'),
                show: true
            }} classes={classes} />
        </div>
    )
}

let MenuItem = React.memo(({ item, classes }: { item: MenuItemDefinition, classes: any }) => {
    if (!item.show) return (<></>)
    return (
        <div className={classes.menuItem}
            onClick={() => item.navigate ? item.navigate() : item.path && navigateTo(item.path)}>
            <div className={classes.menuItemIcon}>{item.icon}</div>
            <div className={classes.menuItemText}>{item.text}</div>
        </div>
    )
}, (p, n) => p.item.show === n.item.show)

let styles = () =>
    createStyles({
        drawer: {
            width: '3.5em',
            flexShrink: 0,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'block'
        },
        drawerPaper: {
            zIndex: 1000,
            transition: 'width 0.2s',
            overflow: 'hidden',
            background: defaultColors.darkBlue.main.color
        },
        drawerClose: {
            width: '3.5em',
        },
        drawerOpen: {
            width: '12.2em',
        },
        menuContainer: {
            marginTop: '2.5em'
        },
        menuItem: {
            color: defaultColors.darkBlue.main.text,
            height: '3em',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.1s',
            '&:hover': {
                backgroundColor: 'rgb(0,0,0,0.08)',
                color: 'white'
            },
            '&:active': {
                backgroundColor: 'rgb(0,0,0,0.15)'
            }
        },
        menuItemText: {
            fontFamily: 'Roboto',
            fontWeight: 300,
            letterSpacing: '0.03em',
            userSelect: 'none'
        },
        menuItemIcon: {
            minWidth: '3.5em',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
        },
        drawerContent: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between'
        },
        menuList: {
            paddingTop: '3em'
        }
    })

export default withStyles(styles, muiOptions)(Menu)
