import { useEffect, useState } from 'react'
import { createContainer } from 'unstated-next'
import moment from 'moment'
import * as api from './../../infrastructure/api'
import { useTableEditionMode } from '../common/customComponents'
import {
    MarketSalesFilters, Company, SupplyProduct, Site, ChannelCustomerSegment,
    MktSaleMovement, MktSaleChart, emptyChart, MovementStatus, MktSales, MktSaleDate, MktSaleShipTosFilters, MktSaleForecastShipToDetails
} from './models'
import { SapCounterparty } from '../deals/dealModels'

export const defaultStartDate = () => moment.utc().startOf('month').format()

function useMktSales() {
    let tableEditMode = useTableEditionMode<MktSaleMovement>()
    let [channelCustomerSegments, setChannelCustomerSegments] = useState<ChannelCustomerSegment[]>([])
    let [mktSalesFilters, setMktSalesFilters] = useState<MarketSalesFilters>(overrideWithSavedFilters({ month: defaultStartDate() }))
    let [showForecastPopup, setShowForecastPopup] = useState<boolean>(false)
    let [mktSaleDates, setMktSaleDates] = useState<MktSaleDate[]>([])
    let [unchangedMktSaleMovements, setUnchangedMktSaleMovements] = useState<MktSaleMovement[]>([])
    let [products, setProducts] = useState<SupplyProduct[]>([])
    let [companies, setCompanies] = useState<Company[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [chart, setChart] = useState<MktSaleChart>(emptyChart)
    let [unit, setUnit] = useState<string | undefined>()
    let [displayMode, setDisplayMode] = useState<'split' | 'graph' | 'table'>('split')
    let [sapCounterpartys, setSapCounterpartys] = useState<SapCounterparty[]>([])

    useEffect(() => {
        let selectedProducts = mktSalesFilters.productId ?? []
        let units = products.filter(x => selectedProducts.includes(x.id)).map(x => x.unit).distinct()
        setUnit(units?.length == 1 ? units[0] : undefined)
    }, [mktSalesFilters, products])

    function overrideWithSavedFilters(baseFilter: MarketSalesFilters): MarketSalesFilters {
        let filters = localStorage.getItem('mktSalesFilters_v2')

        if (filters) {
            let filterObj: MarketSalesFilters = JSON.parse(filters)
            let filtersLength = Object.keys(filterObj).length
            if (filtersLength === 0)
                return baseFilter

            let parseArray = x => Array.isArray(x) ? x : null

            return {
                ...baseFilter,
                companies: parseArray(filterObj.companies),
                site: parseArray(filterObj.site),
                productId: parseArray(filterObj.productId),
                customerSegments: parseArray(filterObj.customerSegments),
            }
        }
        return baseFilter
    }

    let loadMktSaleMovements = async () => {
        if (mktSalesFilters.month && mktSalesFilters.site) {
            let query = constructFiltersQuery(mktSalesFilters)
            let mktSales = await api.get<MktSales>(`stock/movement/mktsale?${query}`)
            setChart(mktSales.chart)
            changeMktSaleMovements(mktSales.movementItems)
            setUnchangedMktSaleMovements(JSON.parse(JSON.stringify(mktSales.movementItems))) // deep copy
        }
    }

    let constructFiltersQuery = (filters: MarketSalesFilters) => {
        let query = ''
        let filterProperties = Object.keys(filters)
        let initializedFilters = filterProperties.filter(x => filters[x] != null && x === 'month')

        initializedFilters.map(x => query = query + `${x}=${filters[x]}&`)
        filters.companies?.map(x => { query = query + `companies=${x}&` })
        filters.customerSegments?.map(x => { query = query + `customerSegments=${x}&` })
        filters.site?.map(x => { query = query + `site=${x}&` })
        filters.productId?.map(x => { query = query + `productId=${x}&` })

        return query
    }

    let constructShipTosFiltersQuery = (filters: MktSaleShipTosFilters) => {
        let query = ''
        let filterProperties = Object.keys(filters)
        let initializedFilters = filterProperties.filter(x => filters[x] != null && x === 'date')

        initializedFilters.map(x => query = query + `dateFrom=${filters[x]}&dateTo=${filters[x]}&`)
        filters.companys?.map(x => { query = query + `companys=${x}&` })
        filters.customerSegments?.map(x => { query = query + `customerSegments=${x}&` })
        filters.sites?.map(x => { query = query + `sites=${x}&` })
        filters.productIds?.map(x => { query = query + `productIds=${x}&` })

        return query
    }

    let closeForecastPopup = async () => {
        setShowForecastPopup(false)
        await loadMktSaleMovements()
    }

    let createMktSaleDates = (movements: MktSaleMovement[]): MktSaleDate[] => {
        let mktSaleDatesIndex = mktSaleDates.indexBy(x => x.date)

        let add = (x, acc) => (acc ?? 0) + (x ?? 0)

        return movements
            .groupBy(x => x.date, x => x)
            .map(([key, items]) => {
                let distinctApStatuses = items.map(x => x.accountingPeriodStatus).distinct()
                let result = {
                    date: key,
                    isOpen: mktSaleDatesIndex[key]?.isOpen ?? false,
                    forecastQuantity: items.map(x => x.forecastQuantity).reduce(add),
                    plannedQuantity: items.map(x => x.plannedQuantity).reduce(add),
                    siteQuantity: items.map(x => x.siteQuantity).reduce(add),
                    accountingPeriodStatus: distinctApStatuses.length == 1 ? distinctApStatuses.first() : null,
                    sapQuantity: items.map(x => x.sapQuantity).reduce(add),
                    quantity: items.map(x => x.quantity).reduce(add),
                    initialVolume: items.map(x => x.initialVolume).reduce(add),
                    longTermPlanVolume: items.map(x => x.longTermPlanVolume).reduce(add),
                    status: getMktSalesStatus(items),
                    movements: items
                }
                return result
            })
    }

    let getMktSalesStatus = (items: MktSaleMovement[]): MovementStatus => {
        if (items.every(x => x.status === MovementStatus.Actual))
            return MovementStatus.Actual

        if (items.some(x => x.status === MovementStatus.Forecast))
            return MovementStatus.Forecast

        if (items.some(x => x.status === MovementStatus.Planned))
            return MovementStatus.Planned

        return MovementStatus.Forecast
    }

    let changeMktSaleMovements = (movememts: MktSaleMovement[]) => setMktSaleDates(createMktSaleDates(movememts))

    let allMktSaleDatesSelected = mktSaleDates.filter(x => x.isOpen).length == mktSaleDates.length

    let toggleAllMktSaleDate = () => {
        let isOpen = !allMktSaleDatesSelected
        for (let x of mktSaleDates) {
            x.isOpen = isOpen
        }
        setMktSaleDates([...mktSaleDates])
    }

    let toggleMktSaleDate = (date: string) => {
        let mktSaleDate = mktSaleDates.find(x => x.date == date)
        if (mktSaleDate)
            mktSaleDate.isOpen = !mktSaleDate.isOpen
        setMktSaleDates([...mktSaleDates])
    }

    let toggleTableEditMode = () => {
        tableEditMode.setIsOn(!tableEditMode.isOn)
    }

    let getShipTos = async (mktSale: MktSaleDate, movementIndex?: number | null) => {
        let mktSaleMovement = movementIndex !== null ? mktSale.movements[movementIndex!] : null

        let filters: MktSaleShipTosFilters = {
            companys: (movementIndex !== null
                ? [companies.find(c => c.code == mktSaleMovement!.company)!.code]
                : mktSalesFilters.companies) ?? [],
            sites: (movementIndex !== null
                ? [sites.find(s => s.code == mktSaleMovement!.site)!.code]
                : mktSalesFilters.site) ?? [],
            date: mktSale.date,
            productIds: (movementIndex !== null
                ? [products.find(p => p.id === mktSaleMovement!.productId)!.id.toString()]
                : mktSalesFilters.productId) ?? [],
            customerSegments: (movementIndex !== null
                ? [mktSaleMovement!.customerSegment!]
                : mktSalesFilters.customerSegments) ?? [],
        }

        let query = constructShipTosFiltersQuery(filters)
        let mktSaleShipTos = await api.get<MktSaleForecastShipToDetails[]>(`stock/movement/mktsale/shipTo?${query}`)
        return mktSaleShipTos
    }

    let loadSapCounterPartys = async () => {
        let dealSapCounterpartys = await api.get<SapCounterparty[]>('deal/sapCounterParty')
        setSapCounterpartys(dealSapCounterpartys)
    }

    return {
        loadMktSaleMovements, showForecastPopup, setShowForecastPopup,
        mktSalesFilters, setMktSalesFilters,
        products, setProducts,
        companies, setCompanies,
        unchangedMktSaleMovements, setUnchangedMktSaleMovements,
        sites, setSites,
        mktSaleDates, changeMktSaleMovements,
        allMktSaleDatesSelected, toggleAllMktSaleDate, toggleMktSaleDate,
        channelCustomerSegments, setChannelCustomerSegments,
        chart, setChart, unit, closeForecastPopup,
        toggleTableEditMode, tableEditMode,
        displayMode, setDisplayMode, getShipTos, sapCounterpartys, loadSapCounterPartys
    }
}

export let MktSalesContainer = createContainer(useMktSales)