import React from 'react'
import { CustomDialog } from "../common/customComponents"
import { SnapshotType } from "./models"
import { t } from '../../infrastructure/i18nextHelper'
import { useSnapshotLabel } from './useSnapshotLabel'

type SnapshotDialogsProps = {
    snapshotType: SnapshotType | null
    isSnapshotCreationDialogOpen: boolean
    hideCreationDialog: () => void
    createSnapshot: (type: SnapshotType) => void
    isSnapshotCancellationDialogOpen: boolean
    hideCancellationDialog: () => void
    cancelSnapshot: (type: SnapshotType) => void
}

export let SnapshotDialogs = ({
    snapshotType,
    isSnapshotCreationDialogOpen,
    hideCreationDialog,
    createSnapshot,
    isSnapshotCancellationDialogOpen,
    hideCancellationDialog,
    cancelSnapshot
}: SnapshotDialogsProps): JSX.Element => {

    if (!snapshotType) return <></>

    let { getSnapshotLabel } = useSnapshotLabel()

    return (
        <>
            <CustomDialog isOpen={isSnapshotCreationDialogOpen}
                title={t('mktSales.forecast.saveForecastDialogTitle')}
                contentText={t('mktSales.forecast.saveForecastDialogText', { type: getSnapshotLabel(snapshotType) })}
                confirmButtonText={t('mktSales.forecast.saveForecast', { type: getSnapshotLabel(snapshotType) })}
                onConfirm={() => createSnapshot(snapshotType)}
                onClose={() => hideCreationDialog()}
                onCancel={() => hideCreationDialog()} />

            <CustomDialog isOpen={isSnapshotCancellationDialogOpen}
                title={t('mktSales.forecast.cancelForecastDialogTitle', { type: getSnapshotLabel(snapshotType) })}
                contentText={t('mktSales.forecast.cancelForecastDialogText', { type: getSnapshotLabel(snapshotType) })}
                confirmButtonText={t('mktSales.forecast.cancelForecast', { type: getSnapshotLabel(snapshotType) })}
                onConfirm={() => cancelSnapshot(snapshotType)}
                onClose={() => hideCancellationDialog()}
                onCancel={() => hideCancellationDialog()} />
        </>)
}
