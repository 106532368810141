import React from 'react'
import { withStyles, createStyles } from '@material-ui/core'
import { ChartData, ChartOptions } from 'chart.js'
import { DataSetInput} from "../common/components/chart";
import { Line } from 'react-chartjs-2'
import { muiOptions } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import { options, prepareDataSet } from '../common/components/chart'
import { MktSaleChart } from './models'
import { hasFeature } from '../../infrastructure/feature'

type Props = {
    chart: MktSaleChart
    hidden: boolean
    classes?: any
}

let _MktSalesChart = React.memo((props: Props) => {
    let { chart, hidden, classes } = props
    return (
        <div className={hidden ? classes.hidden : classes.graph}>
            <Line data={createChartDefinition(chart)} options={chartOptions} />
        </div>
    )
}, (p, n) => p.chart === n.chart && p.hidden === n.hidden)

function createChartDefinition(chart: MktSaleChart): ChartData<'line', Array<number | null>, string> {
    if (!chart) return { labels: [], datasets: [] }

    let datasets: DataSetInput[] = []

    if (hasFeature('MktSalesLongTermPlan')){
        datasets = [{
            label: t('mktSales.chart.longTermPlan'),
            data: chart.longTermPlanForecasts.map(x => x.volume),
            color: 'brown'
        }]
    }

    datasets = [...datasets, {
        label: t('mktSales.chart.initialForecasts'),
        data: chart.initialForecasts.map(x => x.volume),
        color: 'yellow'
    }, {
        label: t('mktSales.chart.forecasts'),
        data: chart.forecasts.map(x => x.volume),
        color: 'red'
    }]

    if (hasFeature('MktSalesSiteVolume')) {
        datasets = [...datasets, {
            label: t('mktSales.chart.sites'),
            data: chart.sites.map(x => x.volume),
            color: 'green'
        }]
    }

    datasets = [...datasets, {
        label: t('mktSales.chart.saps'),
        data: chart.saps.map(x => x.volume),
        color: 'violet'
    }, {
        label: t('mktSales.chart.actualSales'),
        data: chart.actualSales.map(x => x.volume),
        color: 'blue'
    }]

    return {
        labels: chart.actualSales.map(x => x.date.toString()),
        datasets: datasets.map(prepareDataSet)
    }
}

const chartOptions: ChartOptions<'line'> = {
    ...options,
    scales: {
        y: { type: 'linear' },
        x: { type: 'time', time: { unit: 'day', displayFormats: { day: 'MMM DD' } } }
    }
}

let styles = _ =>
    createStyles({
        graph: {
            height: '100%',
            width: '100%',
        },
        hidden: {
            display: 'none'
        }
    })

export let MktSalesChart = withStyles(styles, muiOptions)(_MktSalesChart)