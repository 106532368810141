import React, { useEffect } from 'react'
import { muiOptions, MuiProps, defaultColors } from './../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Typography } from '@material-ui/core'
import { t } from '../../infrastructure/i18nextHelper'
import { hasFeature } from '../../infrastructure/feature'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import FifoDashboard from './reports/fifoDashboard'
import Increments from './reports/increments'
import OverstayAgeing from './reports/overstayAgeing'
import CompulsoryStock from './reports/compulsoryStock'
import StockDgh from './reports/stockDgh'
import Primo from './reports/primo'

export let hasReportAccess = () => Object.values(getAccess()).some(x => x)

export function _report({ classes }: MuiProps) {
    let access = getAccess()

    return (
        <div>
            <Typography className={classes.pageTitle} variant='h5' display='block' gutterBottom>{t('report.name')}</Typography>
            <div className={classes.container}>
                {access.fifoDashboard ? <FifoDashboard /> : undefined}
                {access.overstayAgeing ? <OverstayAgeing /> : undefined}
                {access.compulsoryStock ? <CompulsoryStock /> : undefined}
                {access.dghStock ? <StockDgh /> : undefined}
                {access.primo ? <Primo /> : undefined}
                {access.increments ? <Increments /> : undefined}
            </div>
        </div>
    )
}

let styles = theme =>
    createStyles({
        container: {
            width: '100%',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                marginBottom: '1em',
                '& :last-child': {
                    marginBottom: 0
                }
            }
        },
        pageTitle: {
            color: defaultColors.grey.dark.color,
        }
    })

function getAccess() {
    return {
        fifoDashboard: hasClaim(Claims.FifoDashboardExport),
        overstayAgeing: hasClaim(Claims.OverstayAgeingExport) && hasFeature('overstayAgeing'),
        compulsoryStock: hasClaim(Claims.MasterdataCompulsoryStockExport),
        dghStock: hasClaim(Claims.DghStockExport) && hasFeature('ReportStockDgh'),
        primo: hasClaim(Claims.PxcReportExport) && hasFeature('PricingCalendarExport'),
        increments: hasClaim(Claims.IncrementsExport)
    }
}

export default withStyles(styles, muiOptions)(_report)