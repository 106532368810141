import * as api from './api'
import {useCallback, useEffect, useRef, useState} from 'react'
import {createContainer} from 'unstated-next'

export type FeatureCode = string

function useFeature() {
    let [features, setFeatures] = useState<Feature[]>([])
    let loadingFeaturesPromise = useRef<Promise<Feature[]> | null>(null)

    useEffect(() => { loadFeatures() }, [])
    useEffect(loadRef, [features])

    function _hasFeature(code: FeatureCode, all_features: Feature[]) {
        return !!all_features.find(x => x.name === code)?.isActive
    }

    function hasFeature(code: FeatureCode) {
        return _hasFeature(code, features)
    }

    let loadFeatures = async () => {
        loadingFeaturesPromise.current = getFeatures().then(data => {
            setFeatures(data);
            return data
        })
        await loadingFeaturesPromise.current
    }

    function loadRef() {
        if (hasFeatureRef) hasFeatureRef = hasFeature
    }

    async function hasFeatureAsync(code: FeatureCode): Promise<boolean> {
        if (features.length === 0 && loadingFeaturesPromise.current) {
            let fetchedFeatures = await loadingFeaturesPromise.current
            return _hasFeature(code, fetchedFeatures)
        }
        return hasFeature(code)
    }

    return {hasFeature, hasFeatureAsync, reload: loadFeatures, features}
}

export type Feature = { name: string, isActive: boolean }

export type FeatureCategoryDto = {
    name: string
    features: FeatureDto[]
}

export type FeatureDto = {
    name: string
    isActive: boolean
    scope: string[]
    isCore: boolean
    changedBy: string | null
    changedAt: string | null
    scopeIndexed: { [key: string]: boolean }
}

export let FeatureContainer = createContainer(useFeature)

export let getFeatures = async () => await api.get<Feature[]>('feature')

let hasFeatureRef = (code: FeatureCode) => false
export let hasFeature = (code: FeatureCode) => hasFeatureRef ? hasFeatureRef(code) : false

export let hasSeveralPurchaseMovementTypesFeature = () =>
    hasFeature('VesselAutoCreateDeal')
    && (hasFeature('PricingTrigger')
        || hasFeature('PricingFixedPrice'))

export let hasAutoCreateDealAndHasNotSelectPricingTypeFeature = () =>
    hasFeature('AutoCreateDealOnPurchase')
    && !hasFeature('SelectPricingType')