import React, { useState, useEffect } from 'react'
import { withStyles, createStyles, Typography, Checkbox } from '@material-ui/core'
import { t } from '../../../infrastructure/i18nextHelper'
import { defaultStyles, muiOptions, MuiProps } from '../../../infrastructure/materialUiThemeProvider'
import { hasClaim } from '../../../infrastructure/signIn/userContext'
import { Claims } from '../../../infrastructure/signIn/models'
import { api } from '../../../infrastructure/api'
import guid, { Guid } from '../../../infrastructure/guid'
import { hasFeature } from '../../../infrastructure/feature'
import { ExcelGeneratorContainer } from '../../../infrastructure/excelExport'
import { ColumnDescriptor, NumberField, Select, TableItem } from '../../common/customComponents'
import { Company, DutyStatus, Product, Site } from './models'
import { MasterDataItem, MasterDataShell, createExcelLines } from './masterDataShell'

type StockCapacity = {
    id: Guid,
    product: { productId: Guid },
    company: { code: string },
    site: string,
    dutyStatus: string,
    inStockProjection: boolean,
    associatedPlants: string | null,
    minStockDays: number | null,
    order: number
}

let emptyStockCapacity = (): StockCapacity => {
    return {
        id: guid.createNew(),
        company: { code: '' },
        product: { productId: guid.empty },
        site: '',
        dutyStatus: '',
        inStockProjection: false,
        associatedPlants: null,
        minStockDays: null,
        order: 10
    }
}

let toTableItem = (stockCapacity: StockCapacity): TableItem<MasterDataItem<StockCapacity>> => {
    return {
        id: stockCapacity.id,
        company: stockCapacity.company,
        product: stockCapacity.product,
        site: stockCapacity.site,
        dutyStatus: stockCapacity.dutyStatus,
        inStockProjection: stockCapacity.inStockProjection,
        associatedPlants: stockCapacity.associatedPlants,
        minStockDays: stockCapacity.minStockDays,
        isModified: false,
        order: stockCapacity.order
    }
}

type StockCapacityFilters = {
    company: string | null,
    product: string | null,
    site: string | null,
    dutyStatus: string | null,
}

let noFilters: StockCapacityFilters = {
    company: null,
    product: null,
    site: null,
    dutyStatus: null
}

let applyFilters = (stockCapacitys: StockCapacity[], filters: StockCapacityFilters, companys: Company[], products: Product[], sites: Site[]): StockCapacity[] => {
    let getProductCodeFromId = (id: Guid): string => products.find(x => x.id == id)?.code ?? ''
    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''
    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''

    if (filters.company)
        stockCapacitys = stockCapacitys.filter(x => x.company.code.toLowerCase().contains(filters.company!.toLowerCase()) ||
            getCompanyNameFromCode(x.company.code).toLowerCase().contains(filters.company!.toLowerCase()))
    if (filters.dutyStatus)
        stockCapacitys = stockCapacitys.filter(x => x.dutyStatus.toLowerCase().contains(filters.dutyStatus!.toLowerCase()))
    if (filters.product)
        stockCapacitys = stockCapacitys.filter(x => getProductCodeFromId(x.product.productId).toLowerCase().contains(filters.product!.toLowerCase()))
    if (filters.site)
        stockCapacitys = stockCapacitys.filter(x => getSiteNameFromCode(x.site).toLowerCase().contains(filters.site!.toLowerCase()))

    return stockCapacitys
}

function StockCapacityMasterData({ classes }: MuiProps) {
    let excelGenerator = ExcelGeneratorContainer.useContainer()

    let [stockCapacitys, setStockCapacitys] = useState<TableItem<StockCapacity>[]>([])
    let [companys, setCompanys] = useState<Company[]>([])
    let [products, setProducts] = useState<Product[]>([])
    let [sites, setSites] = useState<Site[]>([])
    let [dutyStatus, setDutyStatus] = useState<DutyStatus[]>([])
    let [filters, setFilters] = useState<StockCapacityFilters>(noFilters)

    let getProductCodeFromId = (id: Guid): string => products.find(x => x.id == id)?.code ?? ''
    let getCompanyNameFromCode = (code: string): string => companys.find(x => x.code == code)?.name ?? ''
    let getSiteNameFromCode = (code: string): string => sites.find(x => x.code == code)?.name ?? ''
    let getInStockProjectionLabel = (value: boolean): string => value
        ? t('admin.masterdata.stockCapacity.inStockProjectionYes')
        : t('admin.masterdata.stockCapacity.inStockProjectionNo')

    let load = async () => {
        let companyPromise = api.get<Company[]>('masterdata/stockCapacity/company')
        let productPromise = api.get<Product[]>('masterdata/stockCapacity/product')
        let sitePromise = api.get<Site[]>('masterdata/stockCapacity/site')
        let stockPromise = api.get<TableItem<StockCapacity>[]>('masterdata/stockCapacity')

        let companys = await companyPromise
        setCompanys(companys)
        setDutyStatus(companys.flatMap(x => x.dutyStatuses).distinct())
        setProducts(await productPromise)
        setSites(await sitePromise)
        setStockCapacitys(await stockPromise)
    }

    useEffect(() => {
        let effect = async () => {
            await load()
        }
        effect()
    }, [])

    let onSave = async (item: StockCapacity) => {
        await api.post('masterdata/stockCapacity', item)
        await load()
        return true
    }

    let onDelete = async (ids: string[]) => {
        let toDelete = stockCapacitys.filter(x => ids.includes(x.id))
        await api.post('masterdata/stockCapacity/delete', { stockCapacitys: toDelete })
        await load()
        return true
    }

    let getItems = () => applyFilters(stockCapacitys, filters, companys, products, sites).map(toTableItem)

    let exportExcel = () => {
        excelGenerator.generate({
            filename: 'StockCapacity.xlsx',
            sheets: [{ name: 'StockCapacity', lines: createExcelLines(getItems(), columns) }]
        })
    }

    let onImportExcel = (file: Blob) => {
        let uploadUrl = 'masterdata/stockCapacity/import'
        api.upload(uploadUrl, file, 'import', { withReport: 'dialog' }).then(_ => load())
    }

    let columns: ColumnDescriptor<TableItem<MasterDataItem<StockCapacity>>>[] = [
        {
            name: t('admin.masterdata.stockCapacity.company'), value: x => getCompanyNameFromCode(x.company.code),
            columnFilter: { value: filters.company ?? '', onChange: (company: string) => setFilters({ ...filters, company: company }) }
        },
        {
            name: t('admin.masterdata.stockCapacity.product'), value: x => getProductCodeFromId(x.product.productId),
            columnFilter: { value: filters.product ?? '', onChange: (product: string) => setFilters({ ...filters, product: product }) }
        },
        {
            name: t('admin.masterdata.stockCapacity.site'), value: x => getSiteNameFromCode(x.site), valueForExport: x => x.site,
            columnFilter: { value: filters.site ?? '', onChange: (site: string) => setFilters({ ...filters, site: site }) }
        },
        {
            name: t('admin.masterdata.stockCapacity.dutyStatus'), value: x => x.dutyStatus,
            columnFilter: { value: filters.dutyStatus ?? '', onChange: (dutyStatus: string) => setFilters({ ...filters, dutyStatus: dutyStatus }) }
        },
        {
            name: t('admin.masterdata.stockCapacity.inStockProjection'),
            value: x => getInStockProjectionLabel(x.inStockProjection)
        },
        {
            name: t('admin.masterdata.stockCapacity.associatedPlants'),
            value: x => x.associatedPlants
        },
        {
            name: t('admin.masterdata.stockCapacity.order'), value: x => x.order
        }
    ]

    let isManager = hasClaim(Claims.MasterdataStockCapacityManager)

    return (
        <div className={classes.container}>
            <MasterDataShell
                tableId={'stock-capacity-table'}
                headerLabel={t('admin.masterdata.stockCapacity.stockCapacitys')}
                itemLabel={t('admin.masterdata.stockCapacity.stockCapacity')}
                isManager={isManager}
                onExportExcel={exportExcel}
                onImportExcel={onImportExcel}
                onNew={emptyStockCapacity}
                onDelete={onDelete}
                onSave={onSave}
                items={getItems()}
                columns={columns}>{
                    (selectedItem, setSelectedItem) => (
                        <>
                            <Select label={t('admin.masterdata.stockCapacity.company')}
                                disabled={!isManager}
                                value={selectedItem.company.code}
                                choices={companys.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, company: { code: val } }) }} />
                            <Select label={t('admin.masterdata.stockCapacity.product')}
                                disabled={!isManager}
                                value={selectedItem.product.productId}
                                choices={products.map(x => ({ value: x.id, text: x.code }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, product: { productId: val } }) }} />
                            <Select label={t('admin.masterdata.stockCapacity.site')}
                                disabled={!isManager}
                                value={selectedItem.site}
                                choices={sites.map(x => ({ value: x.code, text: x.name }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, site: val }) }} />
                            <Select label={t('admin.masterdata.stockCapacity.dutyStatus')}
                                disabled={!isManager}
                                value={selectedItem.dutyStatus}
                                choices={dutyStatus.map(x => ({ value: x, text: x }))}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, dutyStatus: val }) }} />
                            <NumberField
                                label={t('admin.masterdata.stockCapacity.order')}
                                text={selectedItem.order}
                                onChange={val => { if (val) setSelectedItem({ ...selectedItem, order: val }) }} />
                            <div className={classes.inStockProjectionContainer}>
                                <Typography variant='subtitle1'>{t('admin.masterdata.stockCapacity.inStockProjection')}</Typography>
                                <Checkbox checked={selectedItem.inStockProjection} onChange={x => setSelectedItem({ ...selectedItem, inStockProjection: x.target.checked })} />
                            </div>
                        </>
                    )}
            </MasterDataShell>
        </div >)
}

let styles = (theme) =>
    createStyles({
        container: { height: '100%' },
        inStockProjectionContainer: {
            ...defaultStyles.flexRow,
            width: '15.3em',
            justifyContent: 'space-between',
            "& >span": { padding: '0' }
        },
    })

export default withStyles(styles, muiOptions)(StockCapacityMasterData)