import React, { useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles, Paper, Typography, Tooltip } from '@material-ui/core'
import { muiOptions, MuiProps, defaultStyles, defaultColors } from '../../infrastructure/materialUiThemeProvider'
import { t } from '../../infrastructure/i18nextHelper'
import { FeatureContainer } from '../../infrastructure/feature'
import { hasClaim } from '../../infrastructure/signIn/userContext'
import { Claims } from '../../infrastructure/signIn/models'
import { hasFeature } from '../../infrastructure/feature'
import { Button, ColumnDescriptor, DataTable, Link } from '../common/customComponents'
import { StockProjection, ActualStockStatus, StockSimulation, PurchaseType, MktSaleType, UntriggeredType } from './stockModels'
import { StockBoardContainer } from './stockBoardStore'
import { simulatedProjection, simulatedProjectionDays, fieldWithSimulation, fieldSimulated, removeSimulationButton, startEditSimulation } from './_simulationFields'
import { StockProjectionDetailArgs, stockProjectionDetailDialog } from './stockProjectionDetailDialog'
import { TransitStockDetailArgs, transitStockDetailDialog } from './transitStockDetailDialog'
import { closedColor, frozenColor, openedColor } from './actualStockEdit/accountingPeriod/accountingPeriodDialog'
import { StockFiltersContainer } from './filters/filtersStore'

function _StockProjectionTable({ classes, className }: MuiProps & { className?: string }) {
    let store = StockBoardContainer.useContainer()
    let stockFiltersStore = StockFiltersContainer.useContainer()
    let feature = FeatureContainer.useContainer()

    let [simulationEditing, setSimulationEditing] = useState<{ date: string, type: string } | null>(null)
    let [simulatedValue, setSimulatedValue] = useState<StockSimulation | null>(null)

    let handleSimulationValidated = (validation?: StockSimulation) => {
        let toValidate = simulatedValue ?? validation
        if (toValidate)
            store.addOrUpdateSimulation(toValidate)
        setSimulationEditing(null)
        setSimulatedValue(null)
    }

    let openActualStockEditionPopup = () => store.setShowActualStockDisplay(true)
    let openActualStockCreationPopup = () => openActualStockEditionPopup()

    let transitColumn: ColumnDescriptor<StockProjection>[] = feature.hasFeature('TransitVolume') ? [{
        name: t('stock.label.stocks.transit'), tooltipText: t('stock.label.stocks.transitTooltip', { unit: store.unit }),
        value: x => x.volumeInTransit ? Math.round(x.volumeInTransit).toString() : '',
        textAlign: 'right', tourKey: 'stockProjectionTableTransitVolumeColumn', leftBorder: true,
        htmlFor: x => transitStockCell(buildTransitStockDetailProps(x))
    }] : []

    let getStockPercent = (projection: StockProjection) => {
        let value = stockFiltersStore.filters.stockPercentHigh ? projection.highPercent : projection.maxPercent
        return value ? Math.round(value * 100).toString() + '%' : ''
    }

    let stockPercentColumn: ColumnDescriptor<StockProjection>[] = feature.hasFeature('StockPercentUsage') ? [{
        name: t('stock.label.stocks.stockPercent'), tooltipText: t('stock.label.stocks.stockPercentTooltip'),
        value: x => getStockPercent(x),
        textAlign: 'right', tourKey: 'stockProjectionTableSimulatedColumn'
    }] : []

    let simulationColumns: ColumnDescriptor<StockProjection>[] = store.simulations.length > 0 ? [
        {
            name: t('stock.label.stocks.simulated'), tooltipText: t('stock.label.stocks.simulatedTooltip', { unit: store.unit }),
            value: x => x.simulatedStock ? Math.round(x.simulatedStock).toString() : '',
            htmlFor: x => simulatedProjection(x, store.simulations.map(x => x.date), classes),
            textAlign: 'right', tourKey: 'stockProjectionTableSimulatedColumn'
        }, {
            name: t('stock.label.stocks.simulatedDays'), tooltipText: t('stock.label.stocks.simulatedDaysTooltip'),
            value: x => x.simulatedDaysLeft ? Math.round(x.simulatedDaysLeft).toString() : '',
            htmlFor: x => simulatedProjectionDays(x, store.simulations.map(x => x.date), classes),
            textAlign: 'right', tourKey: 'stockProjectionTableSimulatedDaysColumn'
        }] : []

    let shouldDisplayProjectedStockTableAverage = (): boolean => {
        let filterStartDate = moment(stockFiltersStore.filters.start).format('MM/DD/yyyy')
        let filterEndDate = moment(stockFiltersStore.filters.end).format('MM/DD/yyyy')
        let startOfMonth = moment(stockFiltersStore.filters.end).startOf('month').format('MM/DD/yyyy')
        let endOfMonth = moment(stockFiltersStore.filters.end).endOf('month').format('MM/DD/yyyy')

        let isFilterFullMonthSelected = filterStartDate === startOfMonth && filterEndDate === endOfMonth

        return isFilterFullMonthSelected &&
            hasFeature('ProjectedStockTableAverage') &&
            !!store.stockProjectionResult.compulsoryStockLines?.length
    }

    let getDecadeAverageProjectedStock = (): number => {
        let tenthOfTheMonth = moment(stockFiltersStore.filters.start).add(9, 'd').format('yyyy-MM-DD')
        let twentyOfTheMonth = moment(tenthOfTheMonth).add(10, 'd').format('yyyy-MM-DD')
        let endOfTheMonth = moment(stockFiltersStore.filters.start).endOf('month').format('yyyy-MM-DD')

        let getDateProjectedStock = (formatedDate: string): number =>
            store.stockProjectionResult.values?.find(x => x.date.startsWith(formatedDate))?.projectedStock ?? 0

        return [getDateProjectedStock(tenthOfTheMonth),
        getDateProjectedStock(twentyOfTheMonth),
        getDateProjectedStock(endOfTheMonth)].reduce((sum, acc) => sum + acc) / 3
    }

    let changePurchaseSimulation = (simulation: StockSimulation, untriggeredVolume?: number | null) => {
        let purchaseVolumeWithoutUntriggered = simulation.value - (untriggeredVolume ?? 0)
        setSimulatedValue({ ...simulation, value: purchaseVolumeWithoutUntriggered })
    }

    let changeSimulation = (simulation: StockSimulation) => {
        setSimulatedValue(simulation)
    }

    let notEmpty = (collection) =>
        collection != null && collection.length !== 0

    let buildActualStockCalibrationProps = (projection: StockProjection): ActualStockCalibrationProps => {
        let shouldDisplayStockProjectionDetails = notEmpty(stockFiltersStore.filters.dutyStatuses)
            && notEmpty(stockFiltersStore.filters.productIds)
            && notEmpty(stockFiltersStore.filters.companies)
            && notEmpty(stockFiltersStore.filters.sites)
            && hasClaim(Claims.StockManager)

        return {
            actualStock: projection.actualStock,
            actualStockStatus: projection.actualStockStatus,
            date: projection.date,
            shouldDisplayStockProjectionDetails: shouldDisplayStockProjectionDetails ?? false,
            companies: stockFiltersStore.filters.companies,
            dutyStatuses: stockFiltersStore.filters.dutyStatuses,
            sites: stockFiltersStore.filters.sites,
            productIds: stockFiltersStore.filters.productIds
        }
    }

    let buildTransitStockDetailProps = (projection: StockProjection): TransitStockDetailProps => {
        let shouldDisplay = notEmpty(stockFiltersStore.filters.dutyStatuses)
            && notEmpty(stockFiltersStore.filters.productIds)
            && notEmpty(stockFiltersStore.filters.companies)
            && notEmpty(stockFiltersStore.filters.sites)

        return {
            volumeInTransit: projection.volumeInTransit,
            date: projection.date,
            companys: stockFiltersStore.filters.companies,
            dutyStatuss: stockFiltersStore.filters.dutyStatuses,
            sites: stockFiltersStore.filters.sites,
            productIds: stockFiltersStore.filters.productIds,
            shouldDisplayTransitStockDetail: shouldDisplay
        }
    }

    let computeTotalPurchase = (): number | undefined => {
        let getVolume = (volumeTypes: { type: string, volume: number | null }[], type: string): number => {
            return volumeTypes.find(x => x.type === type)?.volume ?? 0
        }

        return store.stockProjectionResult?.values?.reduce(
            (acc, cur) => acc + getVolume(cur.volumeTypes, PurchaseType) + getVolume(cur.volumeTypes, UntriggeredType), 0)
    }

    let stockProjectionColumns: ColumnDescriptor<StockProjection>[] = [
        {
            name: t('stock.label.stocks.date'), value: x => x.date ? moment(x.date).format('MM/DD') : '',
            htmlFor: x => StockTableDateCell(x.date, store.changeStockDateFilter), tourKey: 'stockProjectionTableDateColumn'
        },
        {
            name: t('stock.label.stocks.purchase'), tooltipText: t('stock.label.stocks.purchaseTooltip', { unit: store.unit }),
            tooltipValue: x => {
                let untriggeredVolume = x.volumeTypes.find(x => x.type === UntriggeredType)?.volume
                return untriggeredVolume ? t('stock.label.stocks.purchaseValueTooltip', { untriggered: untriggeredVolume ?? 0, unit: store.unit }) : null
            },
            value: x => {
                let volume = x.volumeTypes.find(x => x.type === PurchaseType)?.volume
                let untriggeredVolume = x.volumeTypes.find(x => x.type === UntriggeredType)?.volume

                return untriggeredVolume
                    ? Math.round(volume ?? 0 + untriggeredVolume).toString()
                    : volume
                        ? Math.round(volume).toString()
                        : ''
            },
            htmlFor: !hasFeature('PurchaseSimulations') ? undefined
                : x => {
                    let simulation = store.simulations.find(simu => simu.date == x.date && simu.movementType == PurchaseType)
                    let purchaseVolume = x.volumeTypes.find(x => x.type === PurchaseType)?.volume
                    let untriggeredVolume = x.volumeTypes.find(x => x.type === UntriggeredType)?.volume
                    let volume = untriggeredVolume
                        ? (purchaseVolume ?? 0) + untriggeredVolume
                        : purchaseVolume

                    if (simulation) {
                        let simulationWithUntriggered = { ...simulation, value: simulation.value + (untriggeredVolume ?? 0) }
                        return fieldSimulated(x, simulationWithUntriggered, PurchaseType, classes)
                    }
                    else
                        return fieldWithSimulation({
                            volume: volume,
                            date: x.date,
                            simulation: simulation,
                            addSimulation: (simulation) => changePurchaseSimulation(simulation, untriggeredVolume),
                            validateSimulation: handleSimulationValidated,
                            isEditMode: !!simulationEditing && simulationEditing.date == x.date && simulationEditing.type == PurchaseType,
                            type: PurchaseType,
                        }, classes)
                },
            total: computeTotalPurchase(),
            totalLabel: t('components.table.sum'), tourKey: 'stockProjectionTablePurchaseColumn',
            textAlign: 'right',
            cellAction: !hasFeature('PurchaseSimulations') ? undefined
                : x => {
                    let simulation = store.simulations.find(simu => simu.date == x.date && simu.movementType == PurchaseType)
                    if (simulation)
                        return removeSimulationButton(() => store.removeSimulation(x.date, PurchaseType))
                    else
                        return startEditSimulation(() => setSimulationEditing({ date: x.date, type: PurchaseType }))
                }
        },
        {
            name: t('stock.label.stocks.mktSale'), tooltipText: t('stock.label.stocks.mktSaleTooltip', { unit: store.unit }),
            value: x => {
                let volume = x.volumeTypes.find(x => x.type === MktSaleType)?.volume
                return volume ? Math.round(volume).toString() : ''
            },
            htmlFor: !hasFeature('MktSalesSimulations') ? undefined
                : x => {
                    let simulation = store.simulations.find(simu => simu.date == x.date && simu.movementType == MktSaleType)
                    let volume = x.volumeTypes.find(x => x.type === MktSaleType)?.volume

                    if (simulation) {
                        let simulationWithUntriggered = { ...simulation, value: simulation.value }
                        return fieldSimulated(x, simulationWithUntriggered, MktSaleType, classes)
                    }
                    else
                        return fieldWithSimulation({
                            volume: volume,
                            date: x.date,
                            simulation: simulation,
                            addSimulation: (simulation) => changeSimulation(simulation),
                            validateSimulation: handleSimulationValidated,
                            isEditMode: !!simulationEditing && simulationEditing.date == x.date && simulationEditing.type == MktSaleType,
                            type: MktSaleType,
                        }, classes)
                },
            total: store.stockProjectionResult?.values?.reduce((acc, cur) => acc + (cur.volumeTypes.find(x => x.type === MktSaleType)?.volume || 0), 0),
            totalLabel: t('components.table.sum'), textAlign: 'right', tourKey: 'stockProjectionTableMktSaleColumn',
            cellAction: !hasFeature('MktSalesSimulations') ? undefined
                : x => {
                    let simulation = store.simulations.find(simu => simu.date == x.date && simu.movementType == MktSaleType)
                    if (simulation)
                        return removeSimulationButton(() => store.removeSimulation(x.date, MktSaleType))
                    else
                        return startEditSimulation(() => setSimulationEditing({ date: x.date, type: MktSaleType }))
                }

        },
        {
            name: t('stock.label.stocks.other'), tooltipText: t('stock.label.stocks.volumeOtherTooltip', { unit: store.unit }),
            value: x => x.volumeOther ? Math.round(x.volumeOther).toString() : '',
            total: store.stockProjectionResult?.values?.reduce((acc, cur) => acc + (cur.volumeOther || 0), 0),
            totalLabel: t('components.table.sum'), textAlign: 'right', tourKey: 'stockProjectionTableVolumeOtherColumn'
        },
        ...transitColumn,
        {
            name: t('stock.label.stocks.projected'), tooltipText: t('stock.label.stocks.projectedTooltip', { unit: store.unit }),
            value: x => x.projectedStock != null ? Math.round(x.projectedStock).toString() : '', textAlign: 'right',
            htmlFor: x => projectedCalibration(x.isProjectedPartial, x.projectedStock, classes),
            total: shouldDisplayProjectedStockTableAverage() ? getDecadeAverageProjectedStock() : null,
            totalLabel: shouldDisplayProjectedStockTableAverage() ? t('components.table.average') : null,
            tourKey: 'stockProjectionTableProjectedColumn', leftBorder: !feature.hasFeature('TransitVolume')
        },
        ...stockPercentColumn,
        {
            name: t('stock.label.stocks.days'), tooltipText: t('stock.label.stocks.daysTooltip'),
            value: x => x.daysLeft ? x.daysLeft.toString() : '', textAlign: 'right',
            htmlFor: x => {
                return store.stockProjectionResult.minDays && x.daysLeft && x.daysLeft <= store.stockProjectionResult.minDays
                    ? <span className={classes.lowStockDays}>{!!x.daysLeft ? Math.trunc(x.daysLeft) : ''}</span>
                    : <span>{!!x.daysLeft ? Math.trunc(x.daysLeft) : ''}</span>
            }, tourKey: 'stockProjectionTableDaysColumn'
        },
        {
            name: t('stock.label.stocks.actual'), tooltipText: t('stock.label.stocks.actualTooltip', { unit: store.unit }),
            value: x => x.actualStock != null ? Math.round(x.actualStock).toString() : '', textAlign: 'right',
            htmlFor: x => actualStockCalibration(buildActualStockCalibrationProps(x), classes), tourKey: 'stockProjectionTableActualColumn'
        },
        ...simulationColumns
    ]

    return (
        <Paper data-tour={'stockProjectionTable'} className={classes.stockTable + (className ? ' ' + className : '')}>
            <div className={classes.rowStockTableHeader}>
                <div className={classes.tableHeaderRow}>
                    <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                        {t('stock.stockTable.' + (stockFiltersStore.filters.usefulStock ? 'usefulStockTitle' : 'title'), { unit: store.unit })}
                    </Typography>
                </div>
                <div>
                    {store.simulations.length > 0 ?
                        <Button label={t('stock.label.clearSimulation')}
                            onClick={() => store.clearSimulation()}
                            tourKey='stockProjectionTableCancelSimulation'
                            className={classes.secondaryButton} />
                        : undefined}
                    <Button tourKey='stockProjectionTableActualVolumesButton'
                        label={t('stock.label.enterActualStocksButton')}
                        onClick={openActualStockCreationPopup}
                        className={classes.secondaryButton} />
                </div>
            </div>
            <div className={classes.stockTableDiv} >
                <DataTable isSelectable={false}
                    idSelector={(x: StockProjection) => x.date}
                    items={store.stockProjectionResult.values ? store.stockProjectionResult.values : []}
                    columns={stockProjectionColumns} />
            </div>
        </Paper>
    )
}

type ActualStockCalibrationProps = {
    actualStockStatus: ActualStockStatus,
    actualStock: number | null,
    date: string,
    companies: string[],
    dutyStatuses: string[],
    productIds: string[],
    sites: string[],
    shouldDisplayStockProjectionDetails: boolean,
}

let actualStockCalibration = (props: ActualStockCalibrationProps, classes) => {
    let options = { tooltip: '', className: '' }
    switch (props.actualStockStatus) {
        case 'FullyCalibrated': options = { tooltip: 'stock.label.calibration', className: classes.calibrationField }; break;
        case 'NotCalibrated': options = { tooltip: 'stock.label.mixed', className: classes.mixedField }; break;
        case 'Mixed': options = { tooltip: 'stock.label.mixed', className: classes.mixedField }; break;
        case 'Opened': options = { tooltip: 'stock.label.opened', className: classes.openedField }; break;
        case 'Frozen': options = { tooltip: 'stock.label.frozen', className: classes.frozenField }; break;
        case 'Closed': options = { tooltip: 'stock.label.closed', className: classes.closedField }; break;
        default: options = { tooltip: '', className: '' };
    }

    function displayStockProjectionDetails(date: string | null) {
        if (!date || !props.shouldDisplayStockProjectionDetails) return

        let args: StockProjectionDetailArgs = {
            date: props.date,
            companys: props.companies,
            dutyStatuss: props.dutyStatuses,
            productIds: props.productIds,
            sites: props.sites
        }

        stockProjectionDetailDialog.open(args)
    }

    return (
        <Link tooltipText={t(options.tooltip)}
            className={options.className}
            onClick={() => displayStockProjectionDetails(props.date)}>
            {props.actualStock != null ? Math.round(props.actualStock).toString() : ''}
        </Link>)
}

type TransitStockDetailProps = {
    volumeInTransit: number | null,
    date: string,
    companys: string[],
    dutyStatuss: string[],
    productIds: string[],
    sites: string[],
    shouldDisplayTransitStockDetail: boolean,
}

let transitStockCell = (props: TransitStockDetailProps) => {
    let displayTransitStockDetail = () => {
        if (!props.shouldDisplayTransitStockDetail || !props.date)
            return

        let args: TransitStockDetailArgs = {
            date: props.date,
            companys: props.companys,
            dutyStatuss: props.dutyStatuss,
            productIds: props.productIds,
            sites: props.sites
        }

        transitStockDetailDialog.open(args)
    }

    let volume = props.volumeInTransit ? Math.round(props.volumeInTransit!).toString() : ''
    return (
        <Link onClick={displayTransitStockDetail}>{volume}</Link>
    )
}

let StockTableDateCell = (date: string, changeDate: (date: string | null) => void) => {
    return (
        <Link tooltipText={'Apply filter'}
            onClick={() => changeDate(date)}>
            {moment(date).format('MM/DD')}
        </Link>)
}

let projectedCalibration = (isProjectedPartial: boolean | null, projectedStock: number | null, classes) =>
    isProjectedPartial
        ? <Tooltip title={<Typography variant='body1'>{t('stock.label.partialProjected')}</Typography>} placement='top'>
            <span className={classes.mixedField}>{!!projectedStock ? Math.round(projectedStock).toString() : ''}</span>
        </Tooltip>
        : <span>{projectedStock != null ? Math.round(projectedStock).toString() : ''}</span>

let styles = _ =>
    createStyles({
        stockTable: {
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        stockTableDiv: {
            height: '100%',
            width: '100%',
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        calibrationField: {
            color: defaultColors.lightBlue.light.color,
            fontWeight: 'bold'
        },
        mixedField: {
            color: defaultColors.orange.light.color,
            fontWeight: 'bold'
        },
        openedField: {
            color: openedColor,
            fontWeight: 'bold'
        },
        frozenField: {
            color: frozenColor,
            fontWeight: 'bold'
        },
        closedField: {
            color: closedColor,
            fontWeight: 'bold'
        },
        lowStockDays: {
            color: defaultColors.red.light.color,
            fontWeight: 'bold'
        },
        row: {
            ...defaultStyles.flexRow,
            alignItems: 'stretch',
            justifyContent: 'space-between',
            margin: '0.2em 1em',
            height: '3.2em'
        },
        rowStockTableHeader: {
            ...defaultStyles.flexRow,
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0.2em 1em'
        },
        tableHeaderRow: {
            ...defaultStyles.flexRow,
            margin: '0.4em 0'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginBottom: '0'
        },
        secondaryButton: {
            ...defaultStyles.secondaryButton,
            marginLeft: '1em'
        },
        simulateEditIcon: {
            opacity: '0%'
        },
        simulationField: {
            maxWidth: '8em'
        },
        simulatedProjected: {
            color: defaultColors.lightBlue.light.color,
            fontWeight: 'bold'
        }
    })

export let StockProjectionTable = withStyles(styles, muiOptions)(_StockProjectionTable)
