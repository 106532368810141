import React, { useState, useRef, MutableRefObject, useEffect } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { defaultColors, defaultStyles, muiOptions, MuiProps } from '../../infrastructure/materialUiThemeProvider'
import { withStyles, createStyles, Paper, Typography, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { post, api } from '../../infrastructure/api'
import { t } from '../../infrastructure/i18nextHelper'
import { SearchField, Select, Switch } from '../common/customComponents'
import { FeatureCategoryDto, FeatureContainer, FeatureDto } from '../../infrastructure/feature'

type FeatureType = 'feature' | 'vesselField'
const featureTypes: FeatureType[] = ['feature', 'vesselField']
const hiddenFeatureToggles: string[] = ['History']

let dialogRef: MutableRefObject<{ open: () => void } | null>

function FeatureToggleAdmin({ classes }: MuiProps) {
    let featureContainer = FeatureContainer.useContainer()
    let [featureType, setFeatureType] = useState<FeatureType>('feature')
    let [countrys, setCountrys] = useState<string[]>([])
    let [featureCategorys, setFeatureCategorys] = useState<FeatureCategoryDto[]>([])
    let [filteredFeatureCategorys, setFilteredFeatureCategorys] = useState<FeatureCategoryDto[]>([])
    let [searchQuery, setSearchQuery] = useState<string>('')
    let [selectedCountry, setSelectedCountry] = useState<string | null>(null)

    dialogRef = useRef<{ open: () => void } | null>(null)

    useEffect(() => { load() }, [])

    let load = async () => {
        let countriesPromise = api.get<{ name: string }[]>('feature/country')
        let featureCategories = await api.get<FeatureCategoryDto[]>('feature/all')
        let countries = (await countriesPromise).map(x => x.name)
        setCountrys(countries)
        featureCategories.forEach(category => category.features
            .forEach(feature => feature.scopeIndexed = countries
                .reduce((acc, x) => {
                    acc[x] = feature.scope.includes(x);
                    return acc
                }, {})))
        let displayedFeatureCategories = featureCategories.map(category =>
            ({ ...category, features: category.features.filter(x => !hiddenFeatureToggles.includes(x.name)) }))
        setFeatureCategorys(displayedFeatureCategories)
    }

    let reload = () => {
        load()
        featureContainer.reload()
    }

    let toggleFeature = async (feature: FeatureDto) => {
        if (selectedCountry) {
            let countrySelected = feature.scope.includes(selectedCountry)
            if (!countrySelected) {
                feature.scope.push(selectedCountry)
                feature.isActive = true
            } else if (feature.isActive) {
                feature.scope = feature.scope.filter(x => x !== selectedCountry)
                if (feature.scope.length === 0)
                    feature.isActive = false
            } else {
                feature.isActive = true
            }
        } else {
            feature.isActive = !feature.isActive
        }
        await post('feature', feature)
        reload()
    }

    let toggleCountry = async (feature: FeatureDto, country: string) => {
        if (feature.scope.includes(country)) {
            feature.scope = feature.scope.filter(x => x !== country)
        } else {
            feature.scope.push(country)
        }
        await post('feature', feature)
        reload()
    }

    let humanizeFeatureName = (name: string) => {
        let splittedName = name.split('.')
        let nameWithoutPrefix = splittedName.length === 2 ? splittedName[1] : name
        let nameCapitalized = nameWithoutPrefix.charAt(0).toUpperCase() + nameWithoutPrefix.slice(1)
        let nameWithSpaces = nameCapitalized.replace(/([a-z])([A-Z])/g, `$1 $2`)
        return nameWithSpaces
    }
    let tOrEmpty = (code: string) => {
        let translation = t(code)
        return translation !== code ? translation : ''
    }
    let formatDate = (date) =>
        new Intl.DateTimeFormat('en', { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })
            .format(new Date(date))

    let onQueryChanged = (input) => {
        setSearchQuery(input)
    }

    let firstLetterToUpper = (str: string | null): string => {
        if (!str) return ''
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    useEffect(() => {
        let featureTypeToUpper = firstLetterToUpper(featureType)
        let featureCategoriesFilteredOnType = featureType === 'feature'
            ? featureCategorys.filter(x => x.features.every(x => !x.name.contains('.')))
            : featureCategorys.filter(x => x.features.every(x => x.name.contains(featureTypeToUpper)))

        if (!searchQuery) {
            setFilteredFeatureCategorys(featureCategoriesFilteredOnType)
        } else {
            let lowerQuery = searchQuery.toLowerCase()
            let result = featureCategoriesFilteredOnType
                .map(category => ({
                    name: category.name,
                    isOpen: true,
                    features: category.features.filter(x => x.name.toLowerCase().includes(lowerQuery))
                }))
                .filter(category => category.features.length > 0)
            setFilteredFeatureCategorys(result)
        }
    }, [searchQuery, featureCategorys, featureType])

    return (
        <div className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container spacing={1} justify='flex-start' alignItems='flex-end'>
                    <Grid item xs={8}>
                        <ToggleButtonGroup
                            value={featureType}
                            exclusive
                            onChange={(_, newValue) => setFeatureType(newValue)}>
                            {featureTypes.map(x =>
                                <ToggleButton key={x} value={x} classes={{ selected: classes.selected }}>
                                    {t(`admin.${x}`)}
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs={2}>
                        <Select label={t('admin.country')} isClearable={true} onChange={x => setSelectedCountry(x)}
                            choices={countrys.map(x => ({ value: x, text: x }))} value={selectedCountry} />
                    </Grid>
                    <Grid item xs={2}>
                        <SearchField onChange={x => onQueryChanged(x.target.value)} text={searchQuery} />
                    </Grid>
                </Grid>
            </Paper>
            {filteredFeatureCategorys.map(category =>
                <Paper key={category.name} className={classes.paper}>
                    <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                        {category.name}
                    </Typography>
                    {category.features.map((x) => (
                        <Grid container key={x.name} className={classes.featureLine} alignItems='center' spacing={1}
                            style={{ backgroundColor: (selectedCountry ? x.isActive && x.scopeIndexed[selectedCountry] : x.isActive) ? defaultColors.focus.dark.color : '' }}>
                            <Grid item xs>
                                <span title={x.changedBy ? `Changed by ${x.changedBy} at ${formatDate(x.changedAt)}` : undefined}>
                                    <Typography variant='h6' className={classes.featureTitle}>{humanizeFeatureName(x.name)}</Typography>
                                    <Typography variant='subtitle1'>{tOrEmpty('admin.features.' + x.name)}</Typography>
                                </span>
                            </Grid>
                            <Grid item xs='auto'>
                                {!selectedCountry && countrys.map(c => <FormControlLabel key={c} control={
                                    <Checkbox style={{ color: x.scopeIndexed[c] ? defaultColors.red.main.color : '' }}
                                        checked={x.scopeIndexed[c]} onChange={() => toggleCountry(x, c)} />} label={c} />)
                                }
                            </Grid>
                            <Grid item xs='auto'>
                                <Switch form isChecked={selectedCountry ? x.isActive && x.scopeIndexed[selectedCountry] : x.isActive}
                                    offText={t('admin.featureOff')} onText={t('admin.featureOn')}
                                    changeCallback={() => toggleFeature(x)} />
                            </Grid>
                        </Grid>
                    ))}
                </Paper>
            )}
        </div>
    )
}

let styles = theme =>
    createStyles({
        paper: {
            width: '100%',
            padding: '1em',
            marginBottom: '1em'
        },
        container: {
            ...defaultStyles.flexColumn,
            marginTop: '2em'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: "1em"
        },
        featureLine: { marginTop: '1em' },
        selected: {
            "&&": {
                backgroundColor: defaultColors.lightBlue.main.color,
                color: 'white',
                '&:hover': {
                    backgroundColor: defaultColors.lightBlue.light.color,
                },
            }
        }
    })

export default withStyles(styles, muiOptions)(FeatureToggleAdmin)