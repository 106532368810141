import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { withStyles, createStyles, Typography, Paper } from '@material-ui/core'
import DateRangeOutlined from '@material-ui/icons/DateRangeOutlined'
import { ChartData, ChartOptions } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { muiOptions, MuiProps, defaultColors, defaultStyles } from '../../../infrastructure/materialUiThemeProvider'
import { t } from '../../../infrastructure/i18nextHelper'
import { LoaderStatusContainer } from '../../../infrastructure/loader'
import { FeatureContainer } from '../../../infrastructure/feature'
import { StockBoardContainer } from '../stockBoardStore'
import { options } from '../../common/components/chart'
import { Switch, Menu } from '../../common/customComponents'
import {
    ChartPhysicalStockLine, ChartStockProjectionLineType, getAllPhysicalStockLinesTypes, StockProjectionMode
} from '../stockModels'
import { createChart } from './createChart'
import { Item } from '../../common/components/select/types'
import { StockFiltersContainer } from '../filters/filtersStore'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

function _StockChart({ classes, className }: MuiProps & { className?: string }) {
    let store = StockBoardContainer.useContainer()
    let stockFiltersStore = StockFiltersContainer.useContainer()
    let loader = LoaderStatusContainer.useContainer()
    let { hasFeature } = FeatureContainer.useContainer()
    let [displayByDays, setDisplayByDays] = useState<boolean>(false)
    let [hiddenProducts, setHiddenProducts] = useState<string[]>([])
    let [selectedLineTypes, setSelectedLineTypes] = useState<ChartStockProjectionLineType[]>([ChartStockProjectionLineType.Min])
    let [disabledDotted, setDisabledDotted] = useState<boolean>(false)

    useEffect(() => {
        if (store.stockMode === StockProjectionMode.Each) {
            let isMonoCompanyAndDutyStatus = stockFiltersStore.filters.companies?.length === 1
                && stockFiltersStore.filters.dutyStatuses?.length === 1
            setDisabledDotted(!isMonoCompanyAndDutyStatus)
            setSelectedLineTypes(isMonoCompanyAndDutyStatus ? [ChartStockProjectionLineType.Min] : [])
        }
    }, [stockFiltersStore.filters.companies, stockFiltersStore.filters.dutyStatuses, store.stockMode])

    let toggleChartMode = () => {
        let nextStockMode = store.stockMode === StockProjectionMode.Sum
            ? StockProjectionMode.Each : StockProjectionMode.Sum
        store.setStockMode(nextStockMode)
        setHiddenProducts([])
    }

    let monthRangeChoices: Item[] = [
        { value: '1', text: '1 ' + t('stock.label.chart.month') },
        { value: '2', text: '2 ' + t('stock.label.chart.months') },
        { value: '3', text: '3 ' + t('stock.label.chart.months') },
        { value: '4', text: '4 ' + t('stock.label.chart.months') },
        { value: '5', text: '5 ' + t('stock.label.chart.months') },
        { value: '6', text: '6 ' + t('stock.label.chart.months') },
    ]

    let dottedLinesTypes = getAllPhysicalStockLinesTypes()

    let toggleProductVisibility = (product?: string, isHidden?: boolean) => {
        if (product != null) {
            let hiddenProductsNames: string[] = []
            if (isHidden)
                hiddenProductsNames = [...hiddenProducts, product]
            else
                hiddenProductsNames = hiddenProducts.filter(x => x !== product)
            setHiddenProducts(hiddenProductsNames)
        }
    }

    let getLineName = (line: ChartPhysicalStockLine) => {
        switch (line.type) {
            case ChartStockProjectionLineType.Min: return t('stock.chart.min') + ' ' + line.name
            case ChartStockProjectionLineType.Max: return t('stock.chart.max') + ' ' + line.name
            case ChartStockProjectionLineType.High: return t('stock.chart.high') + ' ' + line.name
            case ChartStockProjectionLineType.Low: return t('stock.chart.low') + ' ' + line.name
            default: return ''
        }
    }

    let filterAndSetStockProjectionChartLines = () => {
        let selected = hasFeature('DottedCurvesEachProduct') && store.stockMode === StockProjectionMode.Each
            ? store.stockProjectionResult?.physicalStockLines?.filter(x =>
                x.type != null
                && selectedLineTypes.includes(x.type)
                && !hiddenProducts.some(y => y === x.name))
            : store.stockProjectionResult?.physicalStockLines

        return selected?.map(x => { return { ...x, name: getLineName(x) } })
    }

    let chart = React.useMemo(() => {
        let selectedPhysicalStockLines = filterAndSetStockProjectionChartLines()
        return createChart({
            ...store.stockProjectionResult,
            physicalStockLines: selectedPhysicalStockLines ?? [],
            displayByDays,
            stockMode: store.stockMode,
            showTransitVolume: hasFeature('TransitVolume'),
            showLowHighs: true,
            withSimulation: store.simulations.length > 0
        })
    }, [store.stockProjectionResult, hiddenProducts, selectedLineTypes, displayByDays])

    let chartOptions: ChartOptions<'line'> = React.useMemo(() => createChartOptions(chart, toggleProductVisibility), [chart.datasets])
    return (
        <Paper className={classes.container + (className ? ' ' + className : '')}>
            <div className={classes.paperHeader}>
                <Typography className={classes.paperTitle} variant='overline' display='block' gutterBottom>
                    {t('stock.chart.title', { unit: store.unit })}
                </Typography>
                <div className={classes.modeSelectors}>
                    {hasFeature('DottedCurvesEachProduct') && store.stockMode === StockProjectionMode.Each && <div>
                        <ToggleButtonGroup value={selectedLineTypes} size={'small'}
                            onChange={(_, selected) => { setSelectedLineTypes(selected) }}>
                            {dottedLinesTypes.map(x =>
                                <ToggleButton key={x.value} value={x.value} classes={{ selected: classes.selected }}
                                    disabled={loader.isActive || disabledDotted}
                                    selected={selectedLineTypes.some(y => y === x.value)} style={{ padding: '5px', fontSize: '0.6rem' }}>
                                    {x.text}
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </div>}
                    <Switch form changeCallback={() => setDisplayByDays(!displayByDays)} isChecked={displayByDays}
                        disabled={loader.isActive}
                        offText={t('stock.label.chart.volume')} onText={t('stock.label.chart.days')} />
                    <Switch form changeCallback={toggleChartMode} isChecked={store.stockMode === StockProjectionMode.Each}
                        disabled={loader.isActive}
                        offText={t('stock.label.chart.sum')} onText={t('stock.label.chart.each')} />
                    <Menu icon={<DateRangeOutlined />} items={monthRangeChoices} classesOverride={{ button: classes.menuButton }}
                        selected={stockFiltersStore.filters.monthRange} tooltip={t('stock.label.chart.monthRangeTooltip')}
                        onChange={newValue => stockFiltersStore.filters.setMonthRange(parseInt(newValue.toString()))} />
                </div>
            </div>
            <div className={classes.graph}>
                <Line data={chart} options={chartOptions} />
            </div>
        </Paper>
    )
}

function createChartOptions(chart: ChartData<'line', Array<number | null>, string>, onHide?: (product?: string, isHidden?: boolean) => void): ChartOptions<'line'> {
    let today = moment().startOf('day').format('YYYY-MM-DD')
    let isTodayIsInChartLabels = chart.labels?.some(x => x.contains(today))
    let hasStack = chart.datasets.some(x => x.stack === 'stack')

    return {
        ...options,
        plugins: {
            ...options.plugins,
            annotation: {
                annotations: isTodayIsInChartLabels ? [
                    {
                        type: 'line',
                        borderColor: defaultColors.grey.main.color,
                        borderWidth: 3,
                        drawTime: 'beforeDatasetsDraw',
                        xMin: moment().startOf('day').toString(),
                        xMax: moment().startOf('day').toString(),
                    }
                ] : []
            },
            legend: {
                labels: {
                    filter: (legendItem, chartData) => {
                        let index = legendItem.datasetIndex
                        let dataset = index != null ? chartData.datasets[index] : null
                        return dataset?.order === 0
                    },
                },
                onClick: (_e, legendItem, legend) => {
                    let ci = legend.chart as any
                    let indexDataset = legendItem.datasetIndex != null ? ci.data.datasets[legendItem.datasetIndex] : null
                    ci.data.datasets.forEach((dataset, datasetIndex) => {
                        let meta = ci.getDatasetMeta(datasetIndex)
                        if (indexDataset?.stack != null && dataset.stack === indexDataset.stack) {
                            meta.hidden = meta.hidden === null ? true : null
                        }
                    })
                    let indexMeta = ci.getDatasetMeta(legendItem.datasetIndex)
                    onHide && onHide(indexDataset?.stack, indexMeta?.hidden)
                    ci.update()
                }
            }
        },
        scales: {
            x: { type: 'time', time: { unit: 'week', displayFormats: { week: 'MMM DD' } } },
            y: { type: 'linear', stacked: hasStack, },
        }
    }
}

let styles = _ =>
    createStyles({
        container: {
            height: '100%',
            width: '100%',
            paddingBottom: '0.2em',
            paddingLeft: '0.2em'
        },
        graph: {
            height: '88%',
            paddingBottom: '0.5em'
        },
        paperHeader: {
            ...defaultStyles.flexRow,
            justifyContent: 'space-between'
        },
        modeSelectors: {
            display: 'flex',
            alignItems: 'center'
        },
        paperTitle: {
            color: defaultColors.red.main.color,
            marginLeft: '1em',
            marginBottom: '0',
            marginTop: '1em'
        },
        menuButton: {
            padding: '5px'
        }
    })

export let StockChart = withStyles(styles, muiOptions)(_StockChart)