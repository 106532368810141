import { ChartData, ChartDataset } from 'chart.js'
import { t } from '../../../infrastructure/i18nextHelper'
import { prepareDataSet } from '../../common/components/chart'
import {
    ChartStockProjectionLine, ValueDate, ChartPhysicalStockLine, StockProjectionMode,
    getAllPhysicalStockLinesTypes,
    ChartStockProjectionLineType
} from '../stockModels'
import { hasFeature } from '../../../infrastructure/feature'

export function createChart(props: Props): ChartData<'line', Array<number | null>, string> {
    return !props.lines || props.lines.length === 0 ? { labels: [], datasets: [] }
        : props.stockMode === StockProjectionMode.Each
            ? createEachModeData(props)
            : createSumModeData(props)
}

type Props = {
    lines: ChartStockProjectionLine[] | null
    compulsoryStockLines: ValueDate[] | null
    physicalStockLines: ChartPhysicalStockLine[] | null
    displayByDays: boolean
    showLowHighs: boolean
    minDays: number | null
    stockMode: StockProjectionMode
    showTransitVolume: boolean
    withSimulation: boolean
}

function createSumModeData(props: Props): ChartData<'line', Array<number | null>, string> {
    let { lines, displayByDays, minDays, showTransitVolume, compulsoryStockLines, physicalStockLines, showLowHighs } = props

    let line = lines?.find(x => x.withResellerRestriction)
    if (!lines || !line) throw 'no line'

    let datasets: ChartDataset<'line', Array<number | null>>[] = []

    if (displayByDays && minDays) {
        datasets.push(
            prepareDataSet({
                label: t('stock.chart.minDays'),
                data: lines[0].values.map(x => minDays),
                color: 'low'
            })
        )
    }

    let physicalStockLinesTypes = getAllPhysicalStockLinesTypes()
    physicalStockLinesTypes.forEach(line => {
        let physicalLine = physicalStockLines?.first(x => x.type === line.value)
        if (physicalLine?.values?.length && !displayByDays)
            datasets.push(prepareDataSet({
                label: line.text,
                data: physicalLine.values.map(x => x.value),
                color: physicalLine.colorCode,
                stack: physicalLine.name,
            }))
    });

    let lineWtoRsl = lines?.find(x => !x.withResellerRestriction)
    if (lineWtoRsl)
        datasets.push(
            prepareDataSet({
                label: displayByDays ? t('stock.label.chart.days') : t('stock.chart.chartLegend'),
                data: lineWtoRsl.values.map(x => {
                    let value = displayByDays ? x.daysLeft : x.projectedStock
                    return value ? Math.round(value) : null
                }),
                color: mainBlue.line,
                backgroundColor: mainBlue.background,
                fill: 'start',
                stack: 'stack',
            }))

    let simulationLine = props.withSimulation ? lines?.find(x => x.simulatedValues.length > 0) : null
    if (simulationLine)
        datasets.push(
            prepareDataSet({
                label: displayByDays ? t('stock.label.chart.simulatedDays') : t('stock.chart.simulatedChartLegend'),
                data: simulationLine.simulatedValues.map(x => {
                    let value = displayByDays ? x.daysLeft : x.projectedStock
                    return value ? Math.round(value) : null
                }),
                color: simulatedBlue.line,
                backgroundColor: simulatedBlue.background,
                fill: 'start',
                dottedSmall: true,
                stack: 'simulation',
            }))

    datasets.push(
        prepareDataSet({
            label: (displayByDays ?
                t('stock.label.chart.days') : t('stock.chart.chartLegend')) +
                (lines.length > 1 ? ' (%Rsl)' : ''),
            data: line.values.map(x => displayByDays ? x.daysLeft : x.projectedStock),
            color: lineWtoRsl ? resellerBlue.line : mainBlue.line,
            backgroundColor: lineWtoRsl ? resellerBlue.background : mainBlue.background,
            fill: 'start',
            dotted: lines.length > 1,
            stack: 'stack'
        }))

    if (showTransitVolume && !displayByDays)
        datasets.push(
            prepareDataSet({
                label: t('stock.chart.inTransit'),
                data: line.values.map(x => (x.projectedStock ?? 0) >= 0 ? Math.round(x.volumeInTransit ?? 0) : null),
                color: transitLightBlue.line,
                backgroundColor: transitLightBlue.background,
                dotted: true,
                fill: '-1',
                stack: 'stack',
            })
        )

    if (compulsoryStockLines?.length && !displayByDays)
        datasets.push(
            prepareDataSet({
                label: t('stock.chart.compulsoryStock'),
                data: compulsoryStockLines.map(x => x.value),
                color: '#000',
                stack: 'security',
            })
        )

    let labels = line.values.map(x => x.date ?? '')

    return { labels, datasets }
}

function createEachModeData(props: Props): ChartData<'line', Array<number | null>, string> {
    let { lines, physicalStockLines, displayByDays } = props
    if (!lines) throw 'no lines'

    let hasRslRestriction = lines.some(x => !x.withResellerRestriction)

    let offset = (type) => {
        switch (type) {
            case ChartStockProjectionLineType.Min: return 1
            case ChartStockProjectionLineType.Max: return 2
            case ChartStockProjectionLineType.Low: return 3
            case ChartStockProjectionLineType.High: return 4
            default: return 0
        }
    }

    let borderDash = (type) => {
        switch (type) {
            case ChartStockProjectionLineType.Min: return [5, 5]
            case ChartStockProjectionLineType.Max: return [15, 5]
            case ChartStockProjectionLineType.Low: return [10, 5]
            case ChartStockProjectionLineType.High: return [20, 5]
            default: return []
        }
    }

    let sortBool = (a, b) => (a === b) ? 0 : a ? 1 : -1
    let projectedLines = lines
        .sort((a, b) => sortBool(a.withResellerRestriction, b.withResellerRestriction))
        .map(line => prepareDataSet({
            label: line.name
                ? line.name + (hasRslRestriction && line.withResellerRestriction ? ' (%Rsl)' : '')
                : t('stock.chart.chartLegend'),
            data: line.values.map(val => displayByDays ? val.daysLeft : val.projectedStock),
            color: line.colorCode || 'default',
            dotted: hasRslRestriction && line.withResellerRestriction,
            stack: line.name ?? ''
        }))
    let dottedLines = hasFeature('DottedCurvesEachProduct') && !displayByDays
        ? physicalStockLines?.map(line => prepareDataSet({
            label: line.name ?? t('stock.chart.chartLegend'),
            labelHidden: true,
            data: line.values.map(val => val.value),
            color: line.colorCode || 'default',
            borderDashOffset: offset(line.type),
            borderDash: borderDash(line.type),
            stack: line.name ?? ''
        }))
        : null

    return {
        labels: lines[0].values.map(x => x.date ?? ''),
        datasets: dottedLines != null ? [...projectedLines, ...dottedLines] : projectedLines
    }
}

const mainBlue = {
    line: 'rgb(73,101,181, 0.9)',
    background: 'rgb(73,101,181, 0.4)'
}

const simulatedBlue = {
    line: 'rgb(73,101,181, 0.5)',
    background: 'rgb(73,101,181, 0.2)'
}

const resellerBlue = {
    line: 'rgb(129,157,218, 0.9)',
    background: 'rgb(129,157,218, 0.2)'
}

const transitLightBlue = {
    line: 'rgb(184, 212, 254, 0.9)',
    background: 'rgb(184, 212, 254, 0.4)'
}